import { Box, BackgroundImage, SimpleGrid, Image, Select } from "@mantine/core";
import type { LoaderFunctionArgs } from "@remix-run/node";
import { Outlet, useLoaderData, useSubmit } from "@remix-run/react";
import { useTranslation } from "react-i18next";
import { isMobile } from "~/utils/is-mobile.server";

export async function loader(args: LoaderFunctionArgs) {
  return { isMobile: isMobile(args.request) };
}

export default function Auth() {
  const submit = useSubmit();
  const { i18n, t } = useTranslation();
  const { isMobile } = useLoaderData<typeof loader>();

  const isRTL = i18n.language === "ar";

  const bgImage = isMobile
    ? "/images/auth-bg-mobile.webp"
    : `${isRTL ? "/images/auth-bg-ar.webp" : "/images/auth-bg-en.webp"}`;

  return (
    <Box className="w-full h-full">
      <BackgroundImage
        src={bgImage}
        className="md:h-screen h-auto bg-cover relative"
      >
        <Select
          name="value"
          variant="unstyled"
          value={i18n.language}
          className="text-secondary underline absolute top-2 ltr:left-2 rtl:right-2"
          rightSection
          allowDeselect={false}
          onChange={(lang) => {
            if (lang === i18n.language) return;

            submit(
              {
                redirectTo: location.pathname,
                key: "lang",
                value: lang,
              },
              {
                method: "post",
                action: "/set-cookie",
                replace: true,
              },
            );
          }}
          data={[
            {
              value: "en",
              label: "English",
            },
            {
              value: "ar",
              label: "عربي",
            },
          ]}
        />
        <Image
          src={isRTL ? "/images/logoAr.png" : "/images/logoEn.png"}
          className="ltr:w-36 rtl:w-28 h-auto absolute top-4 ltr:right-4 rtl:left-4"
        />
        <SimpleGrid
          cols={{ sm: 1, lg: 2 }}
          spacing={{ lg: "xl", sm: "xl" }}
          verticalSpacing={{ base: "md", sm: "xl" }}
        >
          <div className="hidden md:block ltr:pl-40 rtl:pr-40 pt-10">
            <h1
              className="text-[40px] my-0 font-normal bg-[linear-gradient(to_right,theme(colors.blue.700),theme(colors.violet.600),theme(colors.fuchsia.700),theme(colors.fuchsia.700),theme(colors.blue.700),theme(colors.violet.600),theme(colors.blue.700))]
              bg-clip-text text-transparent"
            >
              {t("auth.header")}
            </h1>
            <h1
              className="text-[40px] my-0 font-normal bg-[linear-gradient(to_right,theme(colors.blue.700),theme(colors.violet.600),theme(colors.fuchsia.700),theme(colors.fuchsia.700),theme(colors.blue.700),theme(colors.violet.600),theme(colors.blue.700))]
              bg-clip-text text-transparent"
            >
              <span className="font-bold">{t("auth.growth")}</span>{" "}
              {t("auth.platform")}
            </h1>
            <h1
              className="text-[40px] my-0 font-normal bg-[linear-gradient(to_right,theme(colors.blue.700),theme(colors.violet.600),theme(colors.fuchsia.700),theme(colors.fuchsia.700),theme(colors.blue.700),theme(colors.violet.600),theme(colors.blue.700))]
              bg-clip-text text-transparent"
            >
              {t("auth.for")}{" "}
              <span className="font-bold">{t("auth.service_providers")}</span>
            </h1>
            <p className="w-[60%] text-secondary text-base">
              {t("auth.paragraph")}
            </p>
          </div>
          <div className="mt-20 flex justify-center md:block md:mt-0 md:pt-40 md:rtl:pr-40 md:ltr:pl-40">
            <Outlet />
          </div>

          <div className="block md:hidden pt-20 pb-40 rtl:pr-10 ltr:pl-10 mb-24">
            <h1
              className="text-[40px] my-0 font-normal bg-[linear-gradient(to_right,theme(colors.blue.700),theme(colors.violet.600),theme(colors.fuchsia.700),theme(colors.fuchsia.700),theme(colors.blue.700),theme(colors.violet.600),theme(colors.blue.700))]
              bg-clip-text text-transparent"
            >
              {t("auth.header")}
            </h1>
            <h1
              className="text-[40px] my-0 font-normal bg-[linear-gradient(to_right,theme(colors.blue.700),theme(colors.violet.600),theme(colors.fuchsia.700),theme(colors.fuchsia.700),theme(colors.blue.700),theme(colors.violet.600),theme(colors.blue.700))]
              bg-clip-text text-transparent"
            >
              <span className="font-bold">{t("auth.growth")}</span>{" "}
              {t("auth.platform")}
            </h1>
            <h1
              className="text-[40px] my-0 font-normal bg-[linear-gradient(to_right,theme(colors.blue.700),theme(colors.violet.600),theme(colors.fuchsia.700),theme(colors.fuchsia.700),theme(colors.blue.700),theme(colors.violet.600),theme(colors.blue.700))]
              bg-clip-text text-transparent"
            >
              {t("auth.for")}{" "}
              <span className="font-bold">{t("auth.service_providers")}</span>
            </h1>
            <p className="w-[60%] text-secondary text-base">
              {t("auth.paragraph")}
            </p>
          </div>

          <Image
            src={
              isRTL
                ? "/images/screen-mobile-ar.webp"
                : "/images/screens-mobile.webp"
            }
            className="absolute bottom-0 md:hidden"
          />
        </SimpleGrid>
      </BackgroundImage>
    </Box>
  );
}
